// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-lyrics-lyrics__lyricWrapper {\n  padding-top: 0px;\n}\n.src-components-lyrics-lyrics__truncate {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n@media only screen and (max-width: 767px) {\n  .src-components-lyrics-lyrics__lyricWrapper {\n    padding-top: 50px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/components/lyrics/lyrics.less"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AAEA;EACE,uBAAA;EACA,gBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AAAF;AAGA;EACE;IACE,iBAAA;EADF;AACF","sourcesContent":[".lyricWrapper {\n  padding-top: 0px;\n}\n\n.truncate {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n\n@media only screen and (max-width: 767px) {\n  .lyricWrapper {\n    padding-top: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lyricWrapper": "src-components-lyrics-lyrics__lyricWrapper",
	"truncate": "src-components-lyrics-lyrics__truncate"
};
export default ___CSS_LOADER_EXPORT___;
