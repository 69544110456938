// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-pages-home-home__colMedia {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.src-pages-home-home__App {\n  width: 800px;\n  margin: 0 auto;\n  text-overflow: ellipsis;\n}\n.src-pages-home-home__hero {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 300px;\n  margin: 80px auto;\n}\n@media only screen and (max-width: 767px) {\n  .src-pages-home-home__App {\n    width: 100%;\n  }\n  .src-pages-home-home__hero {\n    width: unset;\n    margin: 40px auto;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/home.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAEA;EACE,YAAA;EACA,cAAA;EACA,uBAAA;AAAF;AAGA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AADF;AAIA;EACE;IACE,WAAA;EAFF;EAIA;IACE,YAAA;IACA,iBAAA;EAFF;AACF","sourcesContent":[".colMedia {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.App {\n  width: 800px;\n  margin: 0 auto;\n  text-overflow: ellipsis;\n}\n\n.hero {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 300px;\n  margin: 80px auto;\n}\n\n@media only screen and (max-width: 767px) {\n  .App {\n    width: 100%;\n  }\n  .hero {\n    width: unset;\n    margin: 40px auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colMedia": "src-pages-home-home__colMedia",
	"App": "src-pages-home-home__App",
	"hero": "src-pages-home-home__hero"
};
export default ___CSS_LOADER_EXPORT___;
